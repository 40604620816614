import styles from "styles/components/dashboard/dashboardchart.module.scss";

export const generateOptions = (extraNotes) => {
  return {
    colors: ["#f39200"],
    chart: {
      type: "area",
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    xaxis: {
      tooltip: {
        enabled: false,
      },
      crosshairs: {
        show: false,
      },
      type: "datetime",
      labels: {
        style: {
          colors: "#5d5d5d",
          fontFamily: "PT Sans",
          fontWeight: 400,
          fontSize: 12,
        },
        format: "MM/dd/yy",
        trim: false,
        rotate: -45,
        rotateAlways: false,
      },
      axisBorder: {
        show: true,
        color: "#404040",
        height: 1,
        width: "100%",
        offsetX: 0,
        offsetY: 0,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      max: (max) => max + 0.25 * max,
      min: 0,
      forceNiceScale: true,
      labels: {
        formatter: (value) => Math.floor(value),
        style: {
          colors: "#5d5d5d",
          fontFamily: "PT Sans",
          fontWeight: 400,
          fontSize: 12,
        },
      },
    },
    fill: {
      colors: ["#f39200", "#EA7F01"],
      type: "solid",
      opacity: [0.2, 0.4],
    },
    states: {
      hover: {
        filter: {
          type: "none",
        },
      },
      active: {
        filter: {
          type: "none",
        },
      },
    },
    stroke: {
      width: [2, 2],
      curve: "straight",
    },
    dataLabels: {
      enabled: false,
      enabledOnSeries: [1],
    },
    grid: {
      strokeDashArray: 3,
      xaxis: {
        lines: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: true,
        },
      },
      padding: {
        right: 30,
        left: 30,
      },
    },
    legend: {
      show: false,
    },
    tooltip: {
      enabled: true,
      intersect: false,
      shared: true,
      custom: function ({ seriesIndex, dataPointIndex, w }) {
        const data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
        let notes = null;

        if (extraNotes?.[seriesIndex]?.data?.[dataPointIndex]) {
          notes = extraNotes?.[seriesIndex]?.data?.[dataPointIndex].notes;
        }

        return (
          `<div class=${styles.toolTipContainer}>` +
          "<span>" +
          "Keywords: " +
          data.y +
          "</span>" +
          "<span>" +
          "Date: " +
          data.x +
          "</span>" +
          (notes ? "<span>" + "Notes: " + notes + "</span>" : "") +
          "</div>"
        );
      },
    },
    markers: {
      size: 5,
      colors: ["#fff", "transparent"],
      strokeColors: "#f39200",
      strokeWidth: [2, 0],
      strokeOpacity: [1, 0],
      strokeDashArray: 0,
      fillOpacity: 0,
      discrete: [],
      shape: "circle",
      radius: 2,
      offsetX: 0,
      offsetY: 0,
      onClick: undefined,
      onDblClick: undefined,
      showNullDataPoints: true,
      hover: {
        size: undefined,
        sizeOffset: 0,
      },
    },
  };
};
