import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import { decode as heDecode } from 'he';

import styles from "styles/components/dashboard/dashboardchart.module.scss";

import { generateOptions } from "util/dashboardChartOptions";

const DashboardChart = ({ title = "", article = null, ...props }) => {
  const [data] = useState([[...props.data]]);
  const [series, setSeries] = useState([]);
  const [extraNotes, setExtraNotes] = useState([]);

  const options = generateOptions(extraNotes);
  const decodeHtmlCharCodes = str => heDecode(str);

  useEffect(() => {
    if (!data || data.length === 0) return;

    let newSeries = [];
    let newExtraNotes = [];

    for (let chart of data) {
      let newChart = { type: "area", data: [] };
      let newNotes = { data: [] };

      for (let dataPoint of chart) {
        // Check if the data point value is finite
        if (isFinite(dataPoint.keywords)) {
          newChart.data.push({ x: dataPoint.date, y: dataPoint.keywords });
        } else {
          newChart.data.push({ x: dataPoint.date, y: null }); // Set a null value instead of Infinity
        }

        if (dataPoint.notes) {
          newNotes.data.push({ notes: dataPoint.notes });
        } else {
          newNotes.data.push(null);
        }
      }

      newSeries.push(newChart);
      newExtraNotes.push(newNotes);
    }

    setSeries(newSeries);
    setExtraNotes(newExtraNotes);
  }, [data]);

  return (
    <div className={styles.container}>
      <h2 className={styles.title}>
        Keyword Rankings Over Time{" "}
        {title ? (
          <a href={article?.publishedUrl} target="_blank">
            "{decodeHtmlCharCodes(title)}"
          </a>
        ) : (
          ""
        )}
      </h2>
      <div className={styles.chartContainer}>
        {series && series[0]?.data?.every((point) => point.y === 0) ? <ReactApexChart
          options={{
            ...options, yaxis: {
              min: 0,
              max: 10,
              tickAmount: 5
            }
          }}
          series={series}
          type="area"
          height={"95%"}
        /> : <ReactApexChart
          options={options}
          series={series}
          type="area"
          height={"95%"}
        />}
      </div>
    </div>
  );
};

export default DashboardChart;
