import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { getArticleData } from "services/dashboardService";

import styles from "styles/pages/dashboardpagedetails.module.scss";

import DashboardChart from "components/dashboard/DashboardChart";
import DashboardPageDetailsTable from "components/dashboard/DashboardPageDetailsTable";

const DashboardPageDetails = () => {
  const [data, setData] = useState(null);
  const [article, setArticle] = useState(null);

  const params = useParams();

  useEffect(() => {
    const getData = async () => {
      const { article_id } = params;

      const res = await getArticleData(article_id);

      setData(res.data);
      setArticle(res.data.articles[res.data.articles.length - 1]);
    };
    getData();
  }, []);

  return (
    <div className={styles.container}>
      {data && (
        <>
          <DashboardChart
            title={article.title}
            data={data.chart}
            article={article}
          />
          <div className={styles.tableSectionContainer}>
            <DashboardPageDetailsTable
              article={article}
              data={data.article_progress}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default DashboardPageDetails;
