export const options = {
  colors: ["#4bba16"],
  chart: {
    sparkline: {
      enabled: true,
    },
    type: "line",
    zoom: {
      enabled: false,
    },
    toolbar: {
      show: false,
    },
  },
  stroke: {
    width: 2,
  },
  legend: {
    show: false,
  },
  xaxis: {
    show: false,
    labels: {
      show: false,
    },
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
  },
  yaxis: {
    show: false,
    // min: 0,
    max: (max) => max,
    labels: {
      show: false,
      offsetX: -15,
    },
  },
  grid: {
    show: false,
    padding: {
      left: -5,
      top: 1,
      bottom: 1,
    },
  },

  tooltip: {
    enabled: false,
  },
};
