import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import _ from "lodash";
import { decode as heDecode } from 'he';



import styles from "styles/components/dashboard/dashboardpagestable.module.scss";

import KeywordProgressChart from "components/dashboard/KeywordProgressChart";

// 0 = no sort
// 1 = sort desc
// -1 = sort asc
const initialSorts = {
  title: 0,
  firstViewed: 0,
  keywords: 1,
  // topKeywords: 0,
  keywordProgress: 0,
};

const DashboardPagesTable = (props) => {
  const [data] = useState(props.data);
  const [showAmount, setShowAmount] = useState(20);
  const [sorts, setSorts] = useState(initialSorts);

  const navigate = useNavigate();

  const decodeHtmlCharCodes = str => heDecode(str);

  const toggleSorts = (key) => {
    setSorts((prev) => ({
      ...initialSorts,
      [key]: prev[key] === 0 ? 1 : prev[key] === -1 ? 0 : -1,
    }));
  };

  const getOpacity = (key, match) => {
    return sorts[key] === match ? 0.3 : 1;
  };

  const sortData = () => {
    let sortFnc;
    let order;

    const [activeSort] = Object.keys(sorts).filter((key) => sorts[key] !== 0);
    order = sorts[activeSort] === 1 ? "desc" : "asc";

    switch (activeSort) {
      case "title":
        sortFnc = (o) => {
          return o.title.toLowerCase();
        };
        break;
      case "firstViewed":
        sortFnc = (o) => {
          return moment(o.firstViewed, "MM/DD/YY").toDate();
        };
        break;
      case "keywords":
        sortFnc = (o) => {
          return parseInt(o.keywordProgress[o.keywordProgress.length - 1].keyword);
        };
        break;
      // case "topKeywords":
      //   sortFnc = (o) => {
      //     return parseInt(o.top10Keywords);
      //   };
      //   break;
      case "keywordProgress":
        sortFnc = (o) => {
          const dataPoints = [
            ...o.keywordProgress.map(({ keyword }) => parseInt(keyword)),
          ];

          //Sort by biggest/smallest change
          return Math.max(Math.max(...dataPoints) - Math.min(...dataPoints));
        };
        break;
      default:
        sortFnc = () => { };
        order = "";
    }

    return _.orderBy(data, [sortFnc], [order]);
  };

  return (
    <div className={styles.container}>
      <div className={styles.tableHeaderContainer}>
        <div className={`${styles.headerCategoryContainer} ${styles.title}`}>
          <div
            className={styles.headerCategoryClick}
            onClick={() => {
              toggleSorts("title");
            }}
          >
            <h3>Page Title</h3>
            <div className={styles.sortArrowsContainer}>
              <img
                src={"https://mediashower-dev-build.s3.us-east-2.amazonaws.com/build/assets/sortarrowup.svg"}
                style={{
                  opacity: getOpacity("title", -1),
                }}
              />
              <img
                src={"https://mediashower-dev-build.s3.us-east-2.amazonaws.com/build/assets/sortarrowdown.svg"}
                style={{
                  opacity: getOpacity("title", 1),
                }}
              />
            </div>
          </div>
        </div>

        <div
          className={`${styles.headerCategoryContainer} ${styles.firstViewed}`}
        >
          <div
            className={styles.headerCategoryClick}
            onClick={() => {
              toggleSorts("firstViewed");
            }}
          >
            <h3>First Viewed</h3>
            <div className={styles.sortArrowsContainer}>
              <img
                src={"https://mediashower-dev-build.s3.us-east-2.amazonaws.com/build/assets/sortarrowup.svg"}
                style={{
                  opacity: getOpacity("firstViewed", -1),
                }}
              />
              <img
                src={"https://mediashower-dev-build.s3.us-east-2.amazonaws.com/build/assets/sortarrowdown.svg"}
                style={{
                  opacity: getOpacity("firstViewed", 1),
                }}
              />
            </div>
          </div>
        </div>

        <div className={`${styles.headerCategoryContainer} ${styles.keywords}`}>
          <div
            className={styles.headerCategoryClick}
            onClick={() => {
              toggleSorts("keywords");
            }}
          >
            <h3>Keywords</h3>
            <div className={styles.sortArrowsContainer}>
              <img
                src={"https://mediashower-dev-build.s3.us-east-2.amazonaws.com/build/assets/sortarrowup.svg"}
                style={{
                  opacity: getOpacity("keywords", -1),
                }}
              />
              <img
                src={"https://mediashower-dev-build.s3.us-east-2.amazonaws.com/build/assets/sortarrowdown.svg"}
                style={{
                  opacity: getOpacity("keywords", 1),
                }}
              />
            </div>
          </div>
        </div>
        {/* 
        <div
          className={`${styles.headerCategoryContainer} ${styles.topKeywords}`}
        >
          <div
            className={styles.headerCategoryClick}
            onClick={() => {
              toggleSorts("topKeywords");
            }}
          >
            <h3>Top 10 Keywords</h3>
            <div className={styles.sortArrowsContainer}>
              <img
                src={"https://mediashower-dev-build.s3.us-east-2.amazonaws.com/build/assets/sortarrowup.svg"}
                style={{
                  opacity: getOpacity("topKeywords", -1),
                }}
              />
              <img
                src={"https://mediashower-dev-build.s3.us-east-2.amazonaws.com/build/assets/sortarrowdown.svg"}
                style={{
                  opacity: getOpacity("topKeywords", 1),
                }}
              />
            </div>
          </div>
        </div> */}

        <div
          className={`${styles.headerCategoryContainer} ${styles.keywordProgress}`}
        >
          <div
            className={styles.headerCategoryClick}
            onClick={() => {
              toggleSorts("keywordProgress");
            }}
          >
            <h3>Keyword Progress</h3>
            <div className={styles.sortArrowsContainer}>
              <img
                src={"https://mediashower-dev-build.s3.us-east-2.amazonaws.com/build/assets/sortarrowup.svg"}
                style={{
                  opacity: getOpacity("keywordProgress", -1),
                }}
              />
              <img
                src={"https://mediashower-dev-build.s3.us-east-2.amazonaws.com/build/assets/sortarrowdown.svg"}
                style={{
                  opacity: getOpacity("keywordProgress", 1),
                }}
              />
            </div>
          </div>
        </div>
      </div>
      {sortData()
        .slice(0, showAmount)
        .map((row) => {
          return (
            <div
              className={styles.rowContainer}
              key={row.title}
              onClick={() =>
                // parseInt(row.keywords) > 0 &&
                navigate(`/reporting_v3/dashboard/${row.article_id}`)
              }
            >
              <div className={`${styles.rowContentContainer} ${styles.title}`}>
                <h4>{decodeHtmlCharCodes(row.title).replaceAll('â€™', '\'')}</h4>
              </div>
              <div
                className={`${styles.rowContentContainer} ${styles.firstViewed}`}
              >
                <h4>{moment(row.firstViewed).format("MM/DD/YY")}</h4>
              </div>
              <div className={`${styles.rowContentContainer} ${styles.keywords}`}>
                <h4
                className={`${styles.noUnderline}`}
                // className={`${row.keywords == 0 ? styles.noUnderline : null}`}
                >
                  {row.keywordProgress[row.keywordProgress.length - 1].keyword}
                </h4>
              </div>
              {/* <div
              className={`${styles.rowContentContainer} ${styles.topKeywords}`}
            >
              <h4>{row.top10Keywords}</h4>
            </div> */}
              <div
                className={`${styles.rowContentContainer} ${styles.keywordProgress}`}
              >
                {<KeywordProgressChart data={row.keywordProgress} />}
              </div>
            </div>
          )
        })}

      {data && showAmount < data?.length ? (
        <div
          className={styles.displayMoreContainer}
          onClick={() => {
            setShowAmount((prev) => prev + 20);
          }}
        >
          <img src={"https://mediashower-dev-build.s3.us-east-2.amazonaws.com/build/assets/downarrow.svg"} />
          <h5>Display More</h5>
        </div>
      ) : (
        <div className={styles.bottomSpacer}></div>
      )}
    </div>
  );
};

export default DashboardPagesTable;
