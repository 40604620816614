import axios from "axios";

const BASE_URL = window.location.origin


export const getDashboardData = async () => {
  try {
    const response = await fetch(`${BASE_URL}/jsonrpc/reporting/dashboard_data_v3`, {
      method: 'GET',
      credentials: 'include', // This is equivalent to Axios' `withCredentials: true`
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json();
    console.log("data ==> ", data);
    return data;
  } catch (error) {
    console.error("Error fetching dashboard data:", error);
    throw error;
  }
};


export const getArticleData = async (articleId) => {
  try {
    const response = await fetch(`${BASE_URL}/jsonrpc/reporting/detailed_data_v3/${articleId}`, {
      method: 'GET',
      credentials: 'include', // This is equivalent to Axios' `withCredentials: true`
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json();
    console.log("data ==> ", data);
    return data;
  } catch (error) {
    console.error("Error fetching article data:", error);
    throw error;
  }
};


export const dashboardDummyData = `{"data":{"deliveredPages":3,"chart":[{"date":"07/02/22","keywords":25581},{"keywords":25446,"date":"07/13/22"},{"date":"07/14/22","keywords":25446},{"keywords":14814,"date":"07/26/22"}],"keywordRankings":14814,"publishedPages":3,"articles":[{"keywords":"4938","top10Keywords":"105","firstViewed":"03/28/22","title":"sadf","keywordProgress":[{"keyword":"4938"},{"keyword":"8482"},{"keyword":"8482"},{"keyword":"8527"}]},{"firstViewed":"03/28/22","keywordProgress":[{"keyword":"4938"},{"keyword":"8482"},{"keyword":"8482"},{"keyword":"8527"}],"title":"nopi","keywords":"4938","top10Keywords":"105"},{"keywords":"4938","top10Keywords":"105","firstViewed":"01/07/22","keywordProgress":[{"keyword":"4938"},{"keyword":"8482"},{"keyword":"8482"},{"keyword":"8527"}],"title":"bb"}]}}`;
