import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";

import { options } from "../../util/KeywordProgressChartOptions";

const KeywordProgressChart = ({ data }) => {
  const [series, setSeries] = useState([]);

  useEffect(() => {
    let newSeries = [];

    for (let point of data) {
      newSeries.push(parseInt(point.keyword));
    }

    setSeries([{ name: "keywordProgress", data: newSeries }]);

    //Simulate resize to ensure chart rerenders
    window.dispatchEvent(new Event("resize"));
  }, []);

  return (
    <div style={{ height: 30 }}>
      <ReactApexChart
        options={options}
        series={series}
        type="line"
        height={"100%"}
      />
    </div>
  );
};

export default KeywordProgressChart;
