import React, { useState } from "react";

import styles from "styles/components/dashboard/dashboardoverview.module.scss";

const DashboardOverview = (props) => {
  const [data, setData] = useState(props.data);

  return (
    <div className={styles.container}>
      <div className={styles.sectionContainer}>
        <div className={`${styles.iconContainer} ${styles.green}`}>
          <img src={"https://mediashower-dev-build.s3.us-east-2.amazonaws.com/build/assets/paperclip.svg"} />
        </div>
        <div className={styles.contentContainer}>
          <h3>{data.deliveredPages}</h3>
          <h4>Total Pages</h4>
        </div>
        <div className={`${styles.underline} ${styles.green}`}></div>
      </div>
      <div className={styles.sectionContainer}>
        <div className={`${styles.iconContainer} ${styles.blue}`}>
          <img src={"https://mediashower-dev-build.s3.us-east-2.amazonaws.com/build/assets/pagecheck.svg"} />
        </div>
        <div className={styles.contentContainer}>
          <h3>{data.publishedPages}</h3>
          <h4>Published Pages</h4>
        </div>
        <div className={`${styles.underline} ${styles.blue}`}></div>
      </div>
      <div className={styles.sectionContainer}>
        <div className={`${styles.iconContainer} ${styles.orange}`}>
          <img src={"https://mediashower-dev-build.s3.us-east-2.amazonaws.com/build/assets/magnifyingglass.svg"} />
        </div>
        <div className={styles.contentContainer}>
          <h3>{data.completeData.chart[data.completeData.chart.length - 1].keywords}</h3>
          <h4>Keyword Rankings</h4>
        </div>
        <div className={`${styles.underline} ${styles.orange}`}></div>
      </div>
    </div>
  );
};

export default DashboardOverview;
