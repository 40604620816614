import React from "react";
import { Routes, Route } from "react-router-dom";

import styles from "styles/app.module.scss";

import Home from "pages";
import Dashboard from "pages/Dashboard";
import DashboardPageDetails from "pages/DashboardPageDetails";

const App = () => {
  return (
    <div className={styles.container}>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/reporting_v3/dashboard" element={<Dashboard />} />
        <Route
          path="/reporting_v3/dashboard/:article_id"
          element={<DashboardPageDetails />}
        />
      </Routes>
    </div>
  );
};

export default App;
