import React, { useState, useEffect } from "react";

import { getDashboardData } from "services/dashboardService";

import styles from "styles/pages/dashboard.module.scss";

import DashboardOverview from "components/dashboard/DashboardOverview";
import DashboardChart from "components/dashboard/DashboardChart";
import DashboardPagesTable from "components/dashboard/DashboardPagesTable";

const Dashboard = () => {
  const [data, setData] = useState(null);

  useEffect(() => {
    const getData = async () => {
      const res = await getDashboardData();
      debugger
      setData(res.data);
    };
    getData();
  }, []);

  return (
    <div className={styles.container}>
      {data && (
        <>
          <DashboardOverview
            data={{
              deliveredPages: data.deliveredPages,
              keywordRankings: data.keywordRankings,
              publishedPages: data.publishedPages,
              completeData: data
            }}
          />
          <DashboardChart data={data.chart} />
          <DashboardPagesTable data={data.articles} />
        </>
      )}
    </div>
  );
};

export default Dashboard;
