import React, { useState, useEffect } from "react";
import moment from "moment";
import _ from "lodash";

import { decode as heDecode } from 'he';

import styles from "styles/components/dashboard/dashboardpagedetailstable.module.scss";



const DashboardPageDetailsTable = (props) => {
  const [data, setData] = useState(props.data);

  const decodeHtmlCharCodes = str => heDecode(str);

  useEffect(() => {
    setData(_.orderBy(
      data,
      [
        (row) => {
          return moment(row.date, "MM/DD/YY").toDate();
        },
      ],
      ["desc"]
    ))
  }, [])

  return (
    <div className={styles.container}>
      <h3 className={styles.title}>
        Keyword Progress for{" "}
        {props.article.title ? (
          <a href={props?.article?.publishedUrl} target="_blank">
            "{decodeHtmlCharCodes(props?.article?.title).replaceAll('â€™', '\'')}"
          </a>
        ) : (
          ""
        )}
      </h3>
      <div className={styles.subTitleContainer}>
        <h3 className={styles.subTitle}>
          Ranking on {props.data[props.data.length - 1].keywords} keywords
        </h3>
        <img src={"https://mediashower-dev-build.s3.us-east-2.amazonaws.com/build/assets/chevronup.svg"} />
      </div>

      <div className={styles.tableContainer}>
        <div className={styles.tableHeaderContainer}>
          <h4 className={styles.date}>Date</h4>
          <h4 className={styles.keywords}>Keywords</h4>
          {/* <h4 className={styles.topKeywords}>Top 10 Keywords</h4> */}
          <h4 className={styles.keywordChange}>% Change</h4>
        </div>

        {data.map((row, i) => {
          debugger
          var percent = (100 * Math.abs((parseInt(data[i]?.keywords) - parseInt(data[i + 1]?.keywords)) / ((parseInt(data[i]?.keywords) + parseInt(data[i + 1]?.keywords)) / 2))).toFixed(2)
          return (
            <>
              <div className={styles.tableRow}>
                <h6 className={styles.date}>{row.date}</h6>
                <h6 className={styles.keywords}>{row.keywords}</h6>
                {/* <h6 className={styles.topKeywords}>{row.top10Keywords}</h6> */}
                <div className={styles.keywordChange}>
                  <h6>
                    {/* {`${row.percentKeywordChange && row.percentKeywordChange != 0
                      ? `${Math.round(
                        (row.percentKeywordChange) * 100
                      ) / 100
                      }%`
                      : ""
                      }`} */}
                    {(parseInt(data[i]?.keywords) < parseInt(data[i + 1]?.keywords) ? '-' : '')}{percent !== 'NaN' && percent > 0 ? `${percent}%` : ''}
                  </h6>
                  <img
                    src={
                      percent == 0 || percent == 'NaN'
                        ? "https://mediashower-dev-build.s3.us-east-2.amazonaws.com/build/assets/grayhorizontalline.svg"
                        : parseInt(data[i]?.keywords) < parseInt(data[i + 1]?.keywords)
                          ? "https://mediashower-dev-build.s3.us-east-2.amazonaws.com/build/assets/reddownarrow.svg"
                          : "https://mediashower-dev-build.s3.us-east-2.amazonaws.com/build/assets/greenuparrow.svg"
                    }
                  />
                </div>
              </div>
              {i !== data.length - 1 && (
                <div className={styles.tableDivider}></div>
              )}
            </>
          )
        })}
      </div>
    </div>
  );
};

export default DashboardPageDetailsTable;
